import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO, { seoPropsMapping } from "../components/seo"
import Hero from "../components/Hero/Hero"
import ContentType from "../components/CustomTemplate/CustomContentType"
import GuideBlock from "../components/Content/GuideBlock"
import { withPreview } from "gatsby-source-prismic"

const GuidePage = ({ data }) => {
  const { uid, data: pageData } = data.prismicGuidePage

  const og_image = data.prismicGuidePage.data.thumbnail.fixed.src

  const seoProps = seoPropsMapping(pageData, {
    image: og_image,
  })

  return (
    <Layout>
      <SEO {...seoProps} hideFromIndexing={pageData.hide_from_indexing} />
      <div className="default-theme custom-template">
        <Hero page={uid} data={pageData} />
        <GuideBlock page={uid} data={pageData.guide_block} title={pageData.article_overview} />
        {pageData.body.map(section => {
          return <ContentType key={`section-${section.primary.section_id}`} section={section} />
        })}
      </div>
    </Layout>
  )
}

export default withPreview(GuidePage)

export const guideQuery = graphql`
  query guideQuery($uid: String) {
    prismicGuidePage(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_description
        seo_keywords

        og_title
        og_description
        og_image {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        og_type

        twitter_site
        twitter_creator
        twitter_title
        twitter_description
        twitter_image_link {
          fixed(width: 4096) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }

        color_theme
        hero_banner_image {
          alt
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        thumbnail {
          fixed(width: 1200) {
            ...GatsbyPrismicImageFixed_noBase64
          }
        }
        hero_button_label
        hero_button_link
        hero_subtitle
        hero_title {
          text
          html
        }
        title {
          html
          text
        }
        description {
          html
          text
        }
        hero_text_alignment
        article_overview
        guide_block {
          description {
            html
          }
          navigation_text
          title {
            text
          }
        }
        body {
          ... on PrismicGuidePageBody2Column {
            primary {
              section_id
              section_name
              section_theme
              section_background_color
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            slice_label
            slice_type
          }
          ... on PrismicGuidePageBody2ColumnWithImageSlider {
            primary {
              section_id
              section_name
              copy {
                html
              }
              cta_url {
                target
                url
              }
              cta_text
            }
            items {
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicGuidePageBody2ColumnVideo {
            id
            slice_label
            slice_type
            primary {
              copy {
                html
              }
              section_id
              section_name
              section_theme
              section_background_color
              vimeo_id
            }
          }
          ... on PrismicGuidePageBodyFullWidthBanner {
            primary {
              section_id
              section_name
              copy {
                html
              }
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              cta_url {
                url
                target
              }
              cta_text
              theme_color_section
            }
            slice_type
          }
          ... on PrismicGuidePageBodyFullWidthBannerWithVideoOverlay {
            primary {
              section_id
              section_name
              copy {
                html
              }
              video_id
              background_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicGuidePageBody2ColumnWithAccordion {
            primary {
              section_id
              section_name
              copy {
                html
              }
              image {
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              accordion_element_body {
                html
              }
              accordion_element_title
            }
          }
          ... on PrismicGuidePageBody3ColumnWithIcon {
            primary {
              section_id
              section_name
            }
            items {
              icon_image {
                fixed(height: 50) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
              short_copy {
                html
              }
              cta_link_url {
                target
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicGuidePageBody1ColumnContainerSmall {
            primary {
              section_id
              section_name
              copy {
                html
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicGuidePageBody3ColumnGrid {
            primary {
              section_id
              section_name
              section_title {
                text
              }
            }
            items {
              sub_mgmt_items {
                uid
                document {
                  ... on PrismicSubscriptionManagement {
                    data {
                      short_copy {
                        html
                      }
                      short_title
                      icon_image {
                        fixed(height: 60) {
                          ...GatsbyPrismicImageFixed_noBase64
                        }
                      }
                      url_prefix
                      second_url_prefix
                    }
                  }
                }
              }
            }
            slice_type
            slice_label
          }
          ... on PrismicGuidePageBody3ColumnCards {
            primary {
              section_id
              section_name
              section_title_centered {
                text
              }
            }
            items {
              card_body_content {
                html
              }
              card_header_content {
                html
              }
              card_header_image {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicGuidePageBodyScrollerWithBg {
            id
            slice_type
            primary {
              section_name
              section_id
              background_image {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            items {
              slide_title
              slide_copy {
                html
              }
              slide_image_desktop {
                alt
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              slide_image_mobile {
                alt
                fixed(width: 500) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicGuidePageBodyCtaBlock {
            primary {
              section_id
              section_theme
              title {
                html
              }
              description {
                html
              }
              cta_button_link {
                target
                url
              }
              cta_button_text
              section_name
              section_background
              section_vimeo_id
              section_vimeo_height
              section_image {
                fluid(maxWidth: 1200) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_type
          }
          ... on PrismicGuidePageBodyTestimonials {
            primary {
              section_id
              section_name
              testimonials_image {
                fluid(maxWidth: 1400) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
            slice_label
            slice_type
            items {
              case_study
              copy
              source
              logo {
                fixed(width: 200) {
                  ...GatsbyPrismicImageFixed_noBase64
                }
              }
            }
          }
          ... on PrismicGuidePageBodyPromotionComponent {
            primary {
              section_id
              section_name
              section_promo_title
              section_description {
                html
              }
            }
            slice_type
            items {
              item_title
              item_description {
                html
              }
              button_link {
                target
                url
              }
              button_label
              item_image {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicGuidePageBodyImageGallery {
            primary {
              section_id
              section_name
              section_description {
                html
              }
            }
            slice_type
            items {
              item_description {
                html
              }
              item_image {
                alt
                fluid(maxWidth: 600) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
            }
          }
          ... on PrismicGuidePageBodyNumberFact {
            primary {
              section_id
              section_name
              section_title {
                html
              }
              section_description {
                html
              }
            }
            slice_type
            items {
              number_field
              prefix_text {
                html
                raw
              }
              postfix_text {
                html
                raw
              }
              description_text {
                html
              }
            }
          }
          ... on PrismicGuidePageBodyRelatedMaterialComponent {
            primary {
              section_id
              section_name
              section_title {
                html
              }
            }
            items {
              item_icon {
                alt
                fluid(maxWidth: 300) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
                url
              }
              item_doc_title {
                html
              }
              link_item {
                url
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicGuidePageBodyHoverBackgroundZoomBlock {
            primary {
              section_id
              section_name
            }
            slice_type
            items {
              heading {
                html
              }
              description {
                html
              }
              button_link {
                target
                url
              }
              button_link_text
              background {
                alt
                fluid(maxWidth: 760) {
                  ...GatsbyPrismicImageFluid_noBase64
                }
              }
              overlay_color
            }
          }
        }
      }
    }
  }
`
